<template>
<!--    <Marquee-->
<!--       >-->
<!--            <div>Content 1</div>-->
<!--            <div>Content 2</div>-->
<!--            <div>Content 3</div>-->
<!--            <div>Content 4</div>-->
<!--    </Marquee>-->
    <div></div>
</template>

<script>
export default {
    name: "Marque",
    components: {
    }
};
</script>

<style scoped>

</style>