import axios from './axios.http';

const prefix_v1 = '/api/v1';
const requests = {
    login(payload) {
        return axios.post(prefix_v1 + '/users/login', payload)
    },
    signup_user(payload) {
        return axios.post(prefix_v1 + '/users/register', payload)
    }
}

export default requests;
