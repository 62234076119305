<template>
	<!-- Navbar -->
	<nav
		class="navbar navbar-expand-lg top-0 z-index-3 position-absolute mt-4"
		:class="isBlur ? isBlur : 'shadow-none my-2 navbar-transparent w-100'"
	>
		<div class="container ps-2 pe-0">
			<router-link class="navbar-brand font-weight-bolder ms-lg-0 ms-3" :class="darkMode ? 'text-black' : 'text-white'" to="/">
				{{ $t('MainHeaderTitle') }}
			</router-link>
			<button class="shadow-none navbar-toggler ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
				<span class="mt-2 navbar-toggler-icon">
					  <span class="navbar-toggler-bar bar1"></span>
					  <span class="navbar-toggler-bar bar2"></span>
					  <span class="navbar-toggler-bar bar3"></span>
				</span>
			</button>
			<div class="collapse navbar-collapse" id="navigation">
				<ul class="navbar-nav mx-auto">
					<li class="nav-item">
						<router-link class="nav-link d-flex align-items-center me-2 active" aria-current="page" to="/">
							<i class="fa fa-chart-pie opacity-6 me-1" aria-hidden="true" :class="isBlur ? 'text-dark' : 'text-white'"></i>
							{{ $t('NavMainHome') }}
						</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link me-2" to="/signup">
							<i class="fas fa-user-circle opacity-6 me-1" aria-hidden="true" :class="isBlur ? 'text-dark' : 'text-white'"></i>
							{{ $t('NavMainSignUp') }}
						</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link me-2" to="/signin">
							<i class="fas fa-key opacity-6 me-1" aria-hidden="true" :class="isBlur ? 'text-dark' : 'text-white'"></i>
							{{ $t('NavMainSignIn') }}
						</router-link>
					</li>
					<li class="nav-item">
						<a class="nav-link me-2" @click="ltr()" style="cursor: pointer">
							<i class="fas fa-key opacity-6 me-1" aria-hidden="true" :class="isBlur ? 'text-dark' : 'text-white'"></i>
							En
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link me-2" @click="rtl('fa')" style="cursor: pointer">
							<i class="fas fa-key opacity-6 me-1" aria-hidden="true" :class="isBlur ? 'text-dark' : 'text-white'"></i>
							Fa
						</a>
					</li>
<!--					<li class="nav-item">-->
<!--						<a class="nav-link me-2" @click="rtl('ar')">-->
<!--							<i class="fas fa-key opacity-6 me-1" aria-hidden="true" :class="isBlur ? 'text-dark' : 'text-white'"></i>-->
<!--							Ar-->
<!--						</a>-->
<!--					</li>-->
<!--					<li class="nav-item dropdown">-->
<!--						<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--							Language-->
<!--						</a>-->
<!--						<div class="dropdown-menu" aria-labelledby="navbarDropdown">-->
<!--							<a class="dropdown-item" href="#">Action</a>-->
<!--							<a class="dropdown-item" href="#">Another action</a>-->
<!--							<div class="dropdown-divider"></div>-->
<!--							<a class="dropdown-item" href="#">Something else here</a>-->
<!--						</div>-->
<!--					</li>-->
				</ul>
<!--				<ul class="navbar-nav d-lg-block d-none">-->
<!--					<li class="nav-item">-->
<!--						<a-->
<!--							href="https://www.creative-tim.com/product/vue-argon-dashboard"-->
<!--							class="btn btn-sm mb-0 me-1"-->
<!--							:class="isBtn"-->
<!--						>Free download</a-->
<!--						>-->
<!--					</li>-->
<!--				</ul>-->
			</div>
		</div>
	</nav>
	<!-- End Navbar -->
</template>

<script>
import downArrWhite from "@/assets/img/down-arrow-white.svg";
import downArrBlack from "@/assets/img/down-arrow-dark.svg";

export default {
	name: "navbar",
	data() {
		return {
			downArrWhite,
			downArrBlack
		};
	},
	props: {
		btnBackground: String,
		isBlur: String,
		isBtn: { type: String, default: "bg-gradient-light" },
		darkMode: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		rtl(lang) {
			this.$i18n.locale = lang;

			this.$store.state.isRTL = true;
			document.querySelector("html").setAttribute("lang", "ar");
			document.querySelector("html").setAttribute("dir", "rtl");
			document.querySelector("#app").classList.add("rtl");
		},
		ltr() {
			this.$i18n.locale = 'en';

			this.$store.state.isRTL = false;
			document.querySelector("html").removeAttribute("lang");
			document.querySelector("html").removeAttribute("dir");
			document.querySelector("#app").classList.remove("rtl");
		}
	},
	computed: {
		darkModes() {
			return {
				"text-dark": this.darkMode
			};
		}
	}
};
</script>
