import {createRouter, createWebHistory} from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import CryptoShow from "@/views/CryptoShow.vue";
import Signin from "@/views/Signin.vue";
import Signup from "@/views/Signup.vue";

const routes = [
    {
        path: "/",
        name: "First",
        component: Dashboard
    },
    {
        path: "/details/:crypto_id",
        name: "Details",
        component: CryptoShow
    },

    {
        path: "/signin",
        name: "Sign-In",
        component: Signin
    },
    {
        path: "/signup",
        name: "Sign-Up",
        component: Signup
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
});

export default router;
