import axios from "axios";
// import router from "@/router/index";
// import store from "@/managers/index";
import toast from "@/mixins/toast.mixin";

let axios_instance = axios.create({
    // baseURL: process.env.VITE_APP_BASEURL,
    baseURL: 'http://localhost:6000',
});
axios_instance.interceptors.request.use(
    (config) => {
        console.log('Request Sent !', config.url);
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
axios_instance.interceptors.response.use(
    (response) => {
        const { config } = response;
        console.log('Response Get !', config.url, response);
        // const appLoading = document.getElementById("loading");
        // if (appLoading) appLoading.style.display = "none";
        //const originalRequest = config;

        // setTimeout(() => {
        //     store.commit("REMOVE_FROM_LOADIN_LIST", config.url);
        // }, 1000);
        //
        // if (
        //     response &&
        //     response.data.status == 401 &&
        //     response.config.url != "Account/Authenticate"
        // ) {
        //     try {
        //         localStorage.removeItem("accessToken");
        //         localStorage.removeItem("userinfo");
        //         localStorage.removeItem("expire_in");
        //         localStorage.removeItem("expire_DateTime");
        //         localStorage.removeItem("OpenBets");
        //         localStorage.removeItem("ProfitLoss");
        //         localStorage.removeItem("balance");
        //         localStorage.removeItem("exposure");
        //         localStorage.removeItem("casino_is_empty");
        //     } catch {}
        //     router.push("/Auth/Logout");
        // }

        // if (response.data.status === 500) {
            /*if (!isAlreadyFetchingAccessToken) {
              isAlreadyFetchingAccessToken = true;
              store
                .dispatch("authModule/fetchAccessToken")
                .then((access_token) => {
                  isAlreadyFetchingAccessToken = false;

                  if (response.data.success == true) {
                    onAccessTokenFetched(access_token.data.result.accessToken);
                  }
                  //onAccessTokenFetched(access_token);
                });
            }

            const retryOriginalRequest = new Promise((resolve) => {
              addSubscriber((access_token) => {
                store.commit("mainModule/SET_BEARER", access_token);
                originalRequest.headers.Authorization = "Bearer " + access_token;

                resolve(axios(originalRequest));
              });
            });

            return retryOriginalRequest;*/
        // }

        return response.data;
    },
    (error) => {
        const { config, response } = error;
        console.log('Response Error !', config.url, response);

        //const appLoading = document.getElementById("loading");
        //if (appLoading) appLoading.style.display = "none";
        // setTimeout(() => {
        //     store.commit("REMOVE_FROM_LOADIN_LIST", config.url);
        // }, 100);
        // const { config, response: { status } } = error
        //const originalRequest = config;

        // if (
        //     response &&
        //     response.status == 401 &&
        //     response.config.url != "Account/Authenticate"
        // ) {
        //     try {
        //         localStorage.removeItem("accessToken");
        //         localStorage.removeItem("userinfo");
        //         localStorage.removeItem("expire_in");
        //         localStorage.removeItem("expire_DateTime");
        //         localStorage.removeItem("OpenBets");
        //         localStorage.removeItem("ProfitLoss");
        //         localStorage.removeItem("balance");
        //         localStorage.removeItem("exposure");
        //         localStorage.removeItem("casino_is_empty");
        //     } catch {}
        //     router.push("/Auth/Logout");
        // }

        // if (response && response.status === 500) {
        //     if (!response.data?.error) {
        //         response.data = {
        //             error: {
        //                 message:
        //                     response.data ||
        //                     response.statusText ||
        //                     "Internal Server Error",
        //             },
        //         };
        //     }
        // }
        //
        // if (String(error).includes("Network Error")) {
        //     return Promise.reject(
        //         new Error("Please Check Your Network Connection...")
        //     );
        // }

        // if (!response.data.error) {
        //     if (response.data === "") {
        //         response.data = {};
        //     }
        //     response.data["error"] = { message: response.data.result };
        // }

        if (response.data && response.data.message)
            toast.data().toast.error(response.data.message);

        return null;
        // return Promise.reject(response);
    }
);
export default axios_instance;
