import {AuthRequest} from '@/https';

const actions = {
    async login({commit}, payload) {
        return await AuthRequest.login(payload);
    },
    async signup_user({commit}, payload) {
        return await AuthRequest.signup_user(payload);
    }
}
export default actions;
