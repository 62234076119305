<template>
	<div class="container top-0 position-sticky z-index-sticky">
		<div class="row">
			<div class="col-12">
				<navbar
					isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
					v-bind:darkMode="true"
					isBtn="bg-gradient-success"
				/>
			</div>
		</div>
	</div>
	<main class="mt-0 main-content">
		<section>
			<div class="page-header min-vh-100">
				<div class="container">
					<div class="row">
						<div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
							<div class="card card-plain">
								<div class="pb-0 card-header" :class="$i18n.locale === 'en' ? 'text-start' : 'text-end'">
									<h4 class="font-weight-bolder">{{ $t('SignFormHeader') }}</h4>
									<p class="mb-0">{{ $t('SignFormHeaderSub') }}</p>
								</div>
								<div class="card-body">
									<form role="form">
										<div class="mb-3">
											<argon-input type="email" placeholder="Email" name="email" size="lg" />
										</div>
										<div class="mb-3">
											<argon-input type="password" placeholder="Password" name="password" size="lg" />
										</div>
										<argon-switch id="rememberMe">{{ $t('SignFormRemember') }}</argon-switch>

										<div class="text-center">
											<argon-button
												class="mt-4"
												variant="gradient"
												color="success"
												fullWidth
												size="lg"
											>{{ $t('SignFormLoginButtonText') }}</argon-button>
										</div>
									</form>
								</div>
								<div class="px-1 pt-0 text-center card-footer px-lg-2">
									<p class="mx-auto mb-4 text-sm">
										{{ $t('SignFormHaveAnAccount') }}
										<router-link class="text-success text-gradient font-weight-bold" to="/signup">
											{{ $t('SignFormSignUpLink') }}
										</router-link>
									</p>
								</div>
							</div>
						</div>
						<div class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 position-absolute justify-content-center flex-column" :class="$i18n.locale === 'en' ? 'ps-0 end-0' : 'pe-0 start-0'">
							<div class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden background-sign-in">
								<span class="mask bg-gradient-success opacity-6"></span>
								<h4 class="mt-5 text-white font-weight-bolder position-relative">"Attention is the new currency"</h4>
								<p class="text-white position-relative">
									The more effortless the writing looks, the more effort the writer actually put into the process.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];

export default {
	name: "signin",
	components: {
		Navbar,
		ArgonInput,
		ArgonSwitch,
		ArgonButton,
	},
	created() {
		this.$store.state.hideConfigButton = true;
		this.$store.state.showNavbar = false;
		this.$store.state.showSidenav = false;
		this.$store.state.showFooter = false;
		body.classList.remove("bg-gray-100");
	},
	beforeUnmount() {
		this.$store.state.hideConfigButton = false;
		this.$store.state.showNavbar = true;
		this.$store.state.showSidenav = true;
		this.$store.state.showFooter = true;
		body.classList.add("bg-gray-100");
	},
};
</script>

<style>
.background-sign-in {
	background-image: url('../assets/img/back/signup.jpg') !important;
	background-size: cover;
}
</style>
