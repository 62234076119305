<template>

    <div class="container top-0 position-sticky z-index-sticky">
        <div class="row">
            <div class="col-12">
                <navbar
                    isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
                    v-bind:darkMode="true"
                    isBtn="bg-gradient-success"
                />
            </div>
        </div>
    </div>

    <div class="crypto-section pt-100 pb-70">
        <div class="container">
            <div class="row">

                <div class="crypto-details d-grid text-white" v-if="original_crypto !== null">
                    <div class="d-grid crypto-name-symbol">
                        <div :style="$store.state.isRTL ? 'padding-left: 2rem' : 'padding-right: 2rem'">
                            <div class="flag-rank">
                                <h1 class="text-white">{{ original_crypto.rank }}</h1>
                                <p>{{ $t('CryptoSummaryRank') }}</p>
                            </div>
                        </div>
                        <div>
                            <h1 class="text-white" style="padding-bottom: 1rem">{{ original_crypto.name }} ({{ original_crypto.symbol }})</h1>
                            <div class="d-grid crypto-price">
                                <h2 class="text-white">
                                    <span>${{ currency_summary(original_crypto.priceUsd) }}</span>
                                </h2>
                                <h3 class="d-grid crypto-index crypto-rate text-green">
                                    <span>{{ currency_summary(original_crypto.changePercent24Hr) }}%</span>
                                    <i aria-hidden="true" class="caret up icon" style="display: inline-block; color: rgb(24, 198, 131);"></i>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="d-grid crypto-index-number">
                        <div class="row d-grid crypto-index-number-column">
                            <div>
                                <div>
                                    <div class="crypto-medium-header-sub">Market Cap</div>
                                    <span class="crypto-medium-header-value">${{ currency_summary(original_crypto.marketCapUsd) }}</span>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div class="crypto-medium-header-sub">Volume (24Hr)</div>
                                    <span class="crypto-medium-header-value">${{ currency_summary(original_crypto.volumeUsd24Hr) }}</span>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div class="crypto-medium-header-sub">Supply</div>
                                    <span class="crypto-medium-header-value">{{ currency_summary(original_crypto.supply) }}</span> FXS
                                </div>
                            </div>
                        </div>
                        <div class="row d-grid crypto-index-number-column">
                            <div>
                                <a :href="original_crypto.explorer" target="_blank" class="button-shadow">
                                    {{ $t('CryptoSummaryWebsite') }}
                                </a>
                            </div>
                            <div>
                                <a :href="original_crypto.explorer" target="_blank" class="button-shadow">
                                    {{ $t('CryptoSummaryExplorer') }}
                                </a>
                            </div>
                            <div>
                                <a target="_blank" class="button-shadow" href="https://t.me/alborzbroker">
                                    {{ $t('CryptoSummaryBuy') }}
                                </a>
                                <a target="_blank" class="button-shadow" href="https://t.me/alborzbroker">
                                    {{ $t('CryptoSummarySell') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div>
        <div class="container">
            <div class="row">

                <div class="crypto-summary" v-show="original_crypto !== null && original_summary !== null">
                    <div class="crypto-header" v-if="original_crypto !== null && original_summary !== null">
                        <div class="crypto-image-name">
                            <div>
                                <img
                                    :src="'https://assets.coincap.io/assets/icons/' + original_crypto.symbol.toLowerCase() + '@2x.png'"
                                    class="avatar" :class="$store.state.isRTL ? 'ms-3' : 'me-3'"
                                />
                            </div>
                            <div class="crypto-name">
                                <h3>{{ original_crypto.name }} ({{ original_crypto.symbol.toUpperCase() }})</h3>
                                <p>{{ new Date().toLocaleDateString('en-GB', {
                                    day : 'numeric',
                                    month : 'short',
                                    year : 'numeric'
                                }) }}</p>
                            </div>
                        </div>
                        <div class="crypto-stats">

                            <div v-if="$i18n.locale === 'en'">
                                <h5>
                                    <span>{{ $t('CryptoSummaryHigh') }}</span>
                                    <span>${{ hi_price }}</span>
                                </h5>
                                <h5>
                                    <span>{{ $t('CryptoSummaryLow') }}</span>
                                    <span>${{ low_price }}</span>
                                </h5>
                            </div>
                            <div v-if="$i18n.locale === 'en'">
                                <h5>
                                    <span>{{ $t('CryptoSummaryAverage') }}</span>
                                    <span>${{ average_price }}</span>
                                </h5>
                                <h5>
                                    <span>{{ $t('CryptoSummaryChange') }}</span>
                                    <span :class="change_price > 0 ? 'text-green' : 'text-red'">{{ change_price }} %</span>
                                </h5>
                            </div>


                            <div v-if="$i18n.locale !== 'en'">
                                <h5>
                                    <span>{{ $t('CryptoSummaryHigh') }}</span>
                                    <span>${{ hi_price }}</span>
                                </h5>
                                <h5>
                                    <span>{{ $t('CryptoSummaryLow') }}</span>
                                    <span>${{ low_price }}</span>
                                </h5>
                            </div>
                            <div v-if="$i18n.locale !== 'en'">
                                <h5>
                                    <span>{{ $t('CryptoSummaryAverage') }}</span>
                                    <span>${{ average_price }}</span>
                                </h5>
                                <h5>
                                    <span>{{ $t('CryptoSummaryChange') }}</span>
                                    <span :class="change_price > 0 ? 'text-green' : 'text-red'">{{ change_price }}%</span>
                                </h5>
                            </div>


<!--                            <div>-->
<!--                                <h5>-->
<!--                                    <span>HIGH</span>-->
<!--                                    <span>${{ hi_price }}</span>-->
<!--                                </h5>-->
<!--                                <h5>-->
<!--                                    <span>LOW</span>-->
<!--                                    <span>${{ low_price }}</span>-->
<!--                                </h5>-->
<!--                            </div>-->
<!--                            <div>-->
<!--                                <h5>-->
<!--                                    <span>AVERAGE</span>-->
<!--                                    <span>${{ average_price }}</span>-->
<!--                                </h5>-->
<!--                                <h5>-->
<!--                                    <span>CHANGE</span>-->
<!--                                    <span :class="change_price > 0 ? 'text-green' : 'text-red'">{{ change_price }} %</span>-->
<!--                                </h5>-->
<!--                            </div>-->
                        </div>
                    </div>

                    <div class="crypto-chart mt-5" style="direction: ltr;">
                        <ApexChart ref="realtimeChart" type="area" height="350" :options="chartOptions" :series="series"></ApexChart>
                    </div>

                    <div class="mt-5">
                        <div class="list-items">
                            <div @click="signal_duration('1D')"  :class="duration === '1D'  ? 'active' : ''">{{ $t('CryptoChartDuration1D') }}</div>
                            <div @click="signal_duration('1W')"  :class="duration === '1W'  ? 'active' : ''">{{ $t('CryptoChartDuration1W') }}</div>
                            <div @click="signal_duration('1M')"  :class="duration === '1M'  ? 'active' : ''">{{ $t('CryptoChartDuration1M') }}</div>
                            <div @click="signal_duration('3M')"  :class="duration === '3M'  ? 'active' : ''">{{ $t('CryptoChartDuration3M') }}</div>
                            <div @click="signal_duration('6M')"  :class="duration === '6M'  ? 'active' : ''">{{ $t('CryptoChartDuration6M') }}</div>
                            <div @click="signal_duration('1Y')"  :class="duration === '1Y'  ? 'active' : ''">{{ $t('CryptoChartDuration1Y') }}</div>
<!--                            <div @click="signal_duration('ALL')" :class="duration === 'ALL' ? 'active' : ''">ALL</div>-->
                        </div>
                    </div>
                </div>

                <div v-if="original_crypto === null" class="crypto-summary-loading">
                    {{ $t('LoadingText') }}
                </div>

            </div>
        </div>
    </div>

    <div class="feature-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="dreamit-section-title  pb-20">
                        <div class="dreamit-section-sub-title">
                            <h5>MARKETS</h5>
                        </div>
                        <div class="dreamit-section-main-title">
                            <h1>MARKET SUPPORTED</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">

                <div class="card">
                    <div class="card-body px-0 pt-0 pb-2">
                        <div v-if="list_market.length > 0" class="table-responsive p-0">
                            <table class="table align-items-center mb-0">
                                <thead>
                                <tr>
                                    <th colspan="2" class="text-secondary text-uppercase text-xxs font-weight-bolder opacity-7">
                                        Exchange
                                    </th>
                                    <th class="text-secondary text-uppercase text-xxs font-weight-bolder opacity-7 ps-2">
                                        Pair
                                    </th>
                                    <th class="text-center text-secondary text-uppercase text-xxs font-weight-bolder opacity-7">
                                        Price
                                    </th>
                                    <th class="text-center text-secondary text-uppercase text-xxs font-weight-bolder opacity-7">
                                        Volume (24Hr)
                                    </th>
                                    <th class="text-center text-secondary text-uppercase text-xxs font-weight-bolder opacity-7">
                                        Volume (%)
                                    </th>
                                    <th class="text-center text-secondary text-uppercase text-xxs font-weight-bolder opacity-7">
                                        status
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(item, index) of list_market" :key="index">
                                    <tr class="cursor-pointer">
                                        <td colspan="2">
                                            <span class="text-xs text-black font-weight-bold">
                                                {{ item.exchangeId }}
                                            </span>
                                        </td>
                                        <td>
											<span class="text-xs text-black font-weight-bold">
                                                {{ item.baseSymbol }}/{{ item.quoteSymbol }}
											</span>
                                        </td>
                                        <td>
											<span class="text-xs text-black font-weight-bold">
												${{ currency_summary(item.priceUsd) }}
											</span>
                                        </td>
                                        <td class="align-middle text-center text-sm">
											<span class="text-xs text-black font-weight-bold">
												${{ currency_summary(item.volumeUsd24Hr) }}
											</span>
                                        </td>
                                        <td class="align-middle text-center">
											<span class="text-xs text-black font-weight-bold">
												{{ currency_summary(item.volumePercent) }}%
											</span>
                                        </td>
                                        <td class="align-middle text-center">
											<div class="text-xs text-black font-weight-bold circle-green">
											</div>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>

                        <div v-if="list_market.length <= 0" class="crypto-summary-loading">
                            Loading ...
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="footer-section pt-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="footer-location-box">
                        <div class="footer-logo">
                            <img :src="require('@/assets/img/assets/one.png')" alt="Footer-logo">
                        </div>
                        <div class="footer-content">
                            <p>There are many variations of pass of Lorem Ipsum available, but the majority have suffered</p>
                        </div>
                    </div>
                    <div class="footer-social-box">
                        <div class="social-content">
                            <h3>Follow Us</h3>
                        </div>
                        <div class="footer-about-social-icon pt-20">
                            <ul>
                                <li>
                                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="fab fa-pinterest"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="fab fa-linkedin-in"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="widget">
                        <div class="footer-quick-link">
                            <div class="footer-widget-title">
                                <h3>Help Links</h3>
                            </div>
                            <div class="footer-quick-link-list">
                                <ul>
                                    <li><a href="#">What Is ICO</a></li>
                                    <li><a href="#">Token</a></li>
                                    <li><a href="#">Road Map</a></li>
                                    <li><a href="#">Advisor</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="widget">
                        <div class="footer-quick-link-list">
                            <div class="footer-widget-title">
                                <h3>Quick Links</h3>
                            </div>
                            <div class="footer-quick-link-list">
                                <ul>
                                    <li><a href="#">ICO Apps</a></li>
                                    <li><a href="#">White Papers</a></li>
                                    <li><a href="#">Teams</a></li>
                                    <li><a href="#">Join Us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 p-0">
                    <div class="widget">
                        <div class="footer-popular-post ">
                            <div class="footer-widget-title two">
                                <h3>Newsletter</h3>
                            </div>
                            <div class="footer-content-text">
                                <p>Sent Us a Newsletter And Get Update</p>
                            </div>
                            <form>
                                <div class="subscribe-area">
                                    <input class="subscribe-mail-box" type="email" placeholder="Enter Your Email...." required="">
                                    <button class="subscribe-button" type="submit">Subscribe</button>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
            </div>
            <div class="row upper11 mt-50 align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="footer-copyright-text">
                        <p>Copyright © AlborzGroup all rights reserved. </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="footer-copyright-content">
                        <div class="footer-sicial-address-link">
                            <ul>
                                <li><a href="#">Terms Condition</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">FAQ</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import ApexChart from "vue3-apexcharts";
import axios from "axios";

export default {
    name: "Crypto-Show",
    components: {
        ApexChart,
        Navbar
    },
    data() {
        return {
            list_market: [],
            original_summary: null,
            original_crypto: null,
            input_crypto_id: '',

            hi_price: 0,
            low_price: 0,
            average_price: 0,
            change_price: 0,

            duration: '1D',
            interval: 'm1',

            series: [{
                data: []
            }],
            chartOptions: {
                chart: {
                    id: 'area-datetime',
                    type: 'area',
                    height: 450,
                    zoom: {
                        autoScaleYaxis: true
                    },
                    toolbar: {
                        show: false
                    }
                },
                annotations: {
                    yaxis: [{
                        y: 30,
                        borderColor: '#999',
                        // label: {
                        //     show: true,
                        //     text: 'Support',
                        //     style: {
                        //         color: "#fff",
                        //         background: '#00E396'
                        //     }
                        // }
                    }],
                    xaxis: [{
                        // x: new Date('14 Nov 2012').getTime(),
                        borderColor: '#999',
                        yAxisIndex: 0,
                        // label: {
                        //     show: true,
                        //     text: 'Rally',
                        //     style: {
                        //         color: "#fff",
                        //         background: '#775DD0'
                        //     }
                        // }
                    }]
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0,
                    style: 'hollow',
                },
                xaxis: {
                    type: 'datetime',
                    // labels: {
                    //     formatter: function (value) {
                    //         console.log(value);
                    //         return value;
                    //     }
                    // }
                },
                tooltip: {
                    x: {
                        format: 'dd MMM yyyy'
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 100]
                    }
                },
            },
        };
    },
    methods: {
        async initial() {
            let crypto_id = this.$route?.params?.crypto_id;
            console.log(crypto_id)

            this.input_crypto_id = crypto_id;
            await this.initial_crypto_one(this.input_crypto_id);
            await this.initial_summary_show(this.input_crypto_id);
            await this.initial_market_list(this.input_crypto_id);
        },
        async initial_crypto_one(crypto_id) {
            let url = "https://api.coincap.io/v2/assets/" + crypto_id;
            let crypto = await axios.get(url);
            if (crypto && crypto.status !== 200)
                return;

            this.original_crypto = crypto.data.data;
        },
        async initial_summary_show(crypto_id) {
            let [start, end] = this.calculate_time();

            let url = `https://api.coincap.io/v2/assets/${crypto_id.toLowerCase()}/history?interval=${this.interval}&start=${start}&end=${end}`;
            let list = await axios.get(url);
            if (list && list.status !== 200)
                return;

            this.series[0].data = [];
            let summary_list = list.data.data;

            let list_chart = [];
            let min_time = null;
            let max_time = null;

            let min_price = null;
            let max_price = null;
            let total_price = 0;

            summary_list = summary_list.map((item) => {
                let priceUsd = Number(item.priceUsd);
                if (priceUsd > 1) {
                    priceUsd = priceUsd.toFixed(2);
                    item.priceShow = this.currency_summary(priceUsd);
                }
                else {
                    priceUsd = priceUsd.toFixed(10);
                    item.priceShow = priceUsd.toString();
                }

                min_time = Math.min(min_time || item.time, item.time);
                max_time = Math.max(max_time || item.time, item.time);

                min_price = Math.min(min_price || priceUsd, priceUsd);
                max_price = Math.max(max_price || priceUsd, priceUsd);

                total_price += Number(priceUsd);

                list_chart.push([
                    item.time, item.priceUsd
                ]);
                return item;
            })

            // this.change_price = Math.abs(max_price - min_price) / Math.floor((max_price + min_price) / 2) * 100;
            this.change_price = Math.abs(max_price - min_price) / (max_price + min_price) / 2 * 100;
            this.change_price = this.currency_summary(this.change_price);

            if (max_price - min_price > 1) {
                max_price = this.currency_summary(max_price);
                min_price = this.currency_summary(min_price);

                for (const chart_index in list_chart) {
                    list_chart[chart_index][1] = this.currency_summary(list_chart[chart_index][1], false);
                }
            }

            this.chartOptions.xaxis.min = min_time;
            this.chartOptions.xaxis.max = max_time;

            this.hi_price = max_price;
            this.low_price = min_price;

            let average = total_price / summary_list.length;
            console.log(total_price, summary_list.length, average);
            this.average_price = average > 1 ? this.currency_summary(average) : average.toFixed(10);

            this.series[0].data = list_chart;
            this.original_summary = list.data.data;
            this.update_chart();
        },
        async initial_market_list(crypto_id) {
            let url = `https://api.coincap.io/v2/assets/${crypto_id}/markets`;
            let crypto = await axios.get(url);
            if (crypto && crypto.status !== 200)
                return;

            this.list_market = crypto.data.data;
        },

        async signal_duration(duration) {
            this.duration = duration;
            await this.initial_summary_show(this.input_crypto_id);
        },

        currency_summary(amount, doted = true) {
            let number = Number(amount) >= 1.0e+12 ?
                (Number(amount) / 1.0e+12).toFixed(2) + " t"
                : Number(amount) >= 1.0e+9 ?
                    (Number(amount) / 1.0e+9).toFixed(2) + " b"
                    : Number(amount) >= 1.0e+6 ?
                        (Number(amount) / 1.0e+6).toFixed(2) + " m"
                        : Number(amount).toFixed(2);
            // : Number(amount) >= 1.0e+3 ?
            // 	(Number(amount) / 1.0e+3).toFixed(2) + "k"
            // 	: Number(amount);

            if (doted)
                return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            return number;
        },
        calculate_time() {
            let date_now = new Date();
            let date_old = new Date;

            if (this.duration === '1D')  {
                date_old.setDate(date_old.getDate() - 1);
                this.interval = 'm1';
            }
            else if (this.duration === '1W') {
                date_old.setDate(date_old.getDate() - 7);
                this.interval = 'm15';
            }
            else if (this.duration === '1M') {
                date_old.setMonth(date_old.getMonth() - 1);
                this.interval = 'h6';
            }
            else if (this.duration === '3M') {
                date_old.setMonth(date_old.getMonth() - 3);
                this.interval = 'h12';
            }
            else if (this.duration === '6M') {
                date_old.setMonth(date_old.getMonth() - 6);
                this.interval = 'd1';
            }
            else if (this.duration === '1Y') {
                date_old.setFullYear(date_old.getFullYear() - 1);
                this.interval = 'd1';
            }
            else if (this.duration === 'ALL') date_old.setFullYear(2000);

            let start = date_old.getTime();
            let end = date_now.getTime();

            return [start, end];
        },
        update_chart() {
            this.$refs.realtimeChart.updateSeries([{
                data: this.series[0].data,
            }], false, true);
        },
    },
    mounted() {
        this.initial();
    }
}
</script>

<style scoped>
.crypto-section {
    padding-top: 7em !important;
    padding-bottom: 2rem !important;
    /*background-image: linear-gradient(to right, rgb(63, 81, 181), rgb(100, 181, 246));*/
    background-image: linear-gradient(to right, #4AF0C4, rgb(100, 181, 246));
}
.crypto-details {
    grid-template-columns: 45% 55%;
}

.flag-rank {
    background: rgb(24, 198, 131);
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    position: relative;
    text-align: center;
    padding: 1rem;
    width: auto;
    display: inline-block;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 15px -3px;


}
.flag-rank::before {
    content: "";
    width: calc(100% + 20px);
    height: 10px;
    background: inherit;
    position: absolute;
    left: -10px;
    top: 0px;
    border-radius: 8px;
}

.crypto-name-symbol {
    grid-template-columns: auto 1fr;
}

.crypto-price, .crypto-index {
    grid-template-columns: auto 1fr;
    grid-column-gap: 10px;
    font-size: 1.4rem;
}
.crypto-rate {
    font-size: 1.1rem;
    align-items: end;
}

.crypto-index-number {
    grid-template-rows: 1fr 1fr;
}
.crypto-index-number-column {
    grid-template-columns: 1fr 1fr 1fr;
    align-items: end;
}

.crypto-medium-header-sub {
    display: block;
    font-weight: 400;
    padding: 0;
    margin: 0;
    line-height: 1.2em;
    font-size: 1rem;
}
.crypto-medium-header-value {
    font-weight: 700;
    font-size: 1.4rem;
}

.circle-green {
    width: 14px;
    height: 15px;
    border-radius: 50%;
    background-color: rgb(24, 198, 131);
    margin: auto;
}

.list-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    border: 1px solid rgb(24, 198, 131) !important;


    border-radius: 10px;
}
.list-items > div {
    padding: 0.8rem 1rem;
    text-align: center;
    cursor: pointer;

    background-color: white;
    color: rgb(24, 198, 131);
    transition: all 200ms ease;
}
.list-items > div:hover {
    color: white;
    background-color: rgb(24, 198, 131);
}
.list-items > div.active {
    color: white;
    background-color: rgb(24, 198, 131);
}

.list-items > div:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.list-items > div:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

html[dir="rtl"] .list-items > div:first-child {
    border-radius: 0 10px 10px 0;
}
html[dir="rtl"] .list-items > div:last-child {
    border-radius: 10px 0 0 10px;
}
</style>
