import { createApp } from "vue";
import ArgonDashboard from "./argon-dashboard";
import { Marquee } from "@selemondev/vue3-marquee";
import App from "./App.vue";
import store from "./managers";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/css/style.css";
import "@selemondev/vue3-marquee/dist/style.css";
import i18n from './localization/i18n'

// import VueApexCharts from 'vue3-apexcharts';

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(i18n);
appInstance.use(ArgonDashboard);
// appInstance.use(VueApexCharts)

appInstance.component('Marquee', Marquee)
// appInstance.component('ApexChart', VueApexCharts)

appInstance.mount("#app");
