<template>
    <div class="container top-0 position-sticky z-index-sticky">
        <div class="row">
            <div class="col-12">
                <navbar
                        isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
                        v-bind:darkMode="true"
                        isBtn="bg-gradient-success"
                />
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div style="width: 100%; height: 500px;">
            <carousel />
        </div>

        <div style="width: 100%;">
            <marque />
        </div>
    </div>

    <div class="feature-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="dreamit-section-title  pb-20">
                        <div class="dreamit-section-sub-title">
                            <h5>CURRENCY LIST</h5>
                        </div>
                        <div class="dreamit-section-main-title">
                            <h1>OUR SUPPORTED CURRENCIES</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">

                <div class="card">
                    <div class="card-body px-0 pt-0 pb-2">
                        <div v-if="list_crypto.length > 0" class="table-responsive p-0">
                            <table class="table table-crypto align-items-center mb-0">
                                <thead>
                                <tr>
                                    <th class="text-secondary text-uppercase text-xxs font-weight-bolder opacity-7">
                                        {{ $t('CryptoHeaderRank') }}
                                    </th>
                                    <th colspan="2" class="text-secondary text-uppercase text-xxs font-weight-bolder opacity-7 ps-2">
                                        {{ $t('CryptoHeaderName') }}
                                    </th>
                                    <th class="text-center text-secondary text-uppercase text-xxs font-weight-bolder opacity-7">
                                        {{ $t('CryptoHeaderPrice') }}
                                    </th>
                                    <th class="text-center text-secondary text-uppercase text-xxs font-weight-bolder opacity-7">
                                        {{ $t('CryptoHeaderMarketCap') }}
                                    </th>
                                    <th class="text-center text-secondary text-uppercase text-xxs font-weight-bolder opacity-7">
                                        {{ $t('CryptoHeaderVWAP24Hr') }}
                                    </th>
                                    <th class="text-center text-secondary text-uppercase text-xxs font-weight-bolder opacity-7">
                                        {{ $t('CryptoHeaderSupply') }}
                                    </th>
                                    <th class="text-center text-secondary text-uppercase text-xxs font-weight-bolder opacity-7">
                                        {{ $t('CryptoHeaderVolume24Hr') }}
                                    </th>
                                    <th class="text-center text-secondary text-uppercase text-xxs font-weight-bolder opacity-7">
                                        {{ $t('CryptoHeaderChange24Hr') }}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(item, index) of list_crypto" :key="index">
                                    <tr class="cursor-pointer" @click="signal_show_summary(index, item.id)">
                                        <td class="text-center">
                                            <span class="text-xs text-black font-weight-bold">{{ item.rank }}</span>
                                        </td>
                                        <td colspan="2">
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                    <img :src="'https://assets.coincap.io/assets/icons/' + item.symbol.toLowerCase() + '@2x.png'" class="avatar avatar-sm" :class="$store.state.isRTL ? 'ms-3' : 'me-3'"  alt="" />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center">
                                                    <router-link :to="`/details/${item.id}`">
                                                        <h6 class="mb-0 text-sm">{{ item.name }}</h6>
                                                        <p class="text-xs text-secondary mb-0">{{ item.symbol }}</p>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="text-xs text-black font-weight-bold">
                                                ${{ currency_summary(item.priceUsd) }}
                                            </span>
                                        </td>
                                        <td class="align-middle text-center text-sm">
										                        <span class="text-xs text-black font-weight-bold">
												                        ${{ currency_summary(item.marketCapUsd) }}
                                            </span>
                                        </td>
                                        <td class="align-middle text-center">
                                            <span class="text-xs text-black font-weight-bold">
                                                ${{ currency_summary(item.vwap24Hr) }}
                                            </span>
                                        </td>
                                        <td class="align-middle text-center">
                                            <span class="text-xs text-black font-weight-bold">
                                                {{ currency_summary(item.supply) }}
                                            </span>
                                        </td>
                                        <td class="align-middle text-center">
                                            <span class="text-xs text-black font-weight-bold">
												                        ${{ currency_summary(item.volumeUsd24Hr) }}
                                            </span>
                                        </td>
                                        <td class="align-middle text-center">
                                            <span class="text-xs font-weight-bold" :class="item.changePercent24Hr > 0 ? 'text-green' : 'text-red'">
												                        {{ currency_summary(item.changePercent24Hr) }} %
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-if="summary_index === index ">
                                        <td colspan="9">
                                            <div class="crypto-summary" v-if="original_summary !== null">
                                                <div class="crypto-header">
                                                    <div class="crypto-image-name">
                                                        <div>
                                                            <img
                                                                    :src="'https://assets.coincap.io/assets/icons/' + item.symbol.toLowerCase() + '@2x.png'"
                                                                    class="avatar" :class="$store.state.isRTL ? 'ms-3' : 'me-3'"
                                                            />
                                                        </div>
                                                        <div class="crypto-name">
                                                            <h3>{{ item.name }} ({{ item.symbol.toUpperCase() }})</h3>
                                                            <p>{{ new Date().toLocaleDateString('en-GB', {
                                                                day : 'numeric',
                                                                month : 'short',
                                                                year : 'numeric'
                                                            }) }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="crypto-stats">

                                                        <div v-if="$i18n.locale === 'en'">
                                                            <h5>
                                                                <span>{{ $t('CryptoSummaryHigh') }}</span>
                                                                <span>${{ hi_price }}</span>
                                                            </h5>
                                                            <h5>
                                                                <span>{{ $t('CryptoSummaryLow') }}</span>
                                                                <span>${{ low_price }}</span>
                                                            </h5>
                                                        </div>
                                                        <div v-if="$i18n.locale === 'en'">
                                                            <h5>
                                                                <span>{{ $t('CryptoSummaryAverage') }}</span>
                                                                <span>${{ average_price }}</span>
                                                            </h5>
                                                            <h5>
                                                                <span>{{ $t('CryptoSummaryChange') }}</span>
                                                                <span :class="change_price > 0 ? 'text-green' : 'text-red'">{{ change_price }} %</span>
                                                            </h5>
                                                        </div>


                                                        <div v-if="$i18n.locale !== 'en'">
                                                            <h5>
                                                                <span>{{ $t('CryptoSummaryHigh') }}</span>
                                                                <span>${{ hi_price }}</span>
                                                            </h5>
                                                            <h5>
                                                                <span>{{ $t('CryptoSummaryLow') }}</span>
                                                                <span>${{ low_price }}</span>
                                                            </h5>
                                                        </div>
                                                        <div v-if="$i18n.locale !== 'en'">
                                                            <h5>
                                                                <span>{{ $t('CryptoSummaryAverage') }}</span>
                                                                <span>${{ average_price }}</span>
                                                            </h5>
                                                            <h5>
                                                                <span>{{ $t('CryptoSummaryChange') }}</span>
                                                                <span :class="change_price > 0 ? 'text-green' : 'text-red'">{{ change_price }}%</span>
                                                            </h5>
                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="crypto-chart mt-5" style="direction: ltr;">
                                                    <ApexChart type="area" height="350" ref="chart" :options="chartOptions" :series="series"></ApexChart>
                                                </div>

                                                <div class="text-center mt-5">
                                                    <router-link :to="`/details/${item.id}`" class="button-shadow ma">
                                                        {{ $t('MoreDetailText') }}
                                                    </router-link>
                                                </div>
                                            </div>

                                            <div v-if="original_summary === null" class="crypto-summary-loading">
                                                {{ $t('LoadingText') }}
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>

                        <div v-if="list_crypto.length <= 0" class="crypto-summary-loading">
                            {{ $t('LoadingText') }}
                        </div>
                    </div>
                </div>

                <div class="text-center mt-4" v-if="load_more_show">
                    <button class="button-shadow ma" @click="signal_load_more">
                        {{ $t('MoreText') }}
                    </button>
                </div>

            </div>
        </div>
    </div>

    <div class="choose-section pt-100 pb-70" id="why">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="dreamit-section-title  pb-20">
                        <div class="dreamit-section-sub-title">
                            <h5>REASON FOR CHOOSE US</h5>
                        </div>
                        <div class="dreamit-section-main-title">
                            <h1>Why Choose Our Token?</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pt-25">
                <div class="col-lg-3 col-md-6">
                    <div class="choose-single-box">
                        <div class="choose-thumb">
                            <img :src="require('@/assets/img/why/one.png')" alt="">
                        </div>
                        <div class="choose-content">
                            <h2>Mobile payment
                                make easy</h2>
                            <p>You can use mobile device
                                to pay with simple steps in
                                value. compellingly</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="choose-single-box two">
                        <div class="choose-thumb">
                            <img :src="require('@/assets/img/why/two.png')" alt="">
                        </div>
                        <div class="choose-content">
                            <h2>Sercurity &amp; control
                                over money</h2>
                            <p>You can use mobile device
                                to pay with simple steps in
                                value. compellingly</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="choose-single-box three">
                        <div class="choose-thumb">
                            <img :src="require('@/assets/img/why/three.png')" alt="">
                        </div>
                        <div class="choose-content">
                            <h2>Protect your
                                Privacy</h2>
                            <p>You can use mobile device
                                to pay with simple steps in
                                value. compellingly</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="choose-single-box four">
                        <div class="choose-thumb">
                            <img :src="require('@/assets/img/why/four.png')" alt="">
                        </div>
                        <div class="choose-content">
                            <h2>Lifetime free
                                transaction</h2>
                            <p>You can use mobile device
                                to pay with simple steps in
                                value. compellingly</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="blog-section pt-95 pb-70" id="blog">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="dreamit-section-title pb-30">
                        <div class="dreamit-section-sub-title">
                            <h5>OUR LATEST NEWS</h5>
                        </div>
                        <div class="dreamit-section-main-title">
                            <h1>Latest <span>Insights</span></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pt-30">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-box">
                        <div class="blog-thumb">
                            <img :src="require('@/assets/img/blog/blog2.jpg')" alt="">
                            <div class="blog-meta">
                                <div class="meta-content">
                                    <h4>31</h4>
                                    <span>Dec</span>
                                </div>
                            </div>
                        </div>
                        <div class="blog-content">
                            <div class="blog-title">
                                <a href="blog-details.html"><h2>How &amp; Where To Market Your ICO Startup</h2></a>
                            </div>
                            <div class="blog-content-text">
                                <p>Monotonectally fabricate extensible in the
                                    after fully tested functionalities industries
                                    business experiences</p>
                            </div>
                            <div class="blog-btn">
                                <a href="#">Read More <i class="fas fa-plus"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-box">
                        <div class="blog-thumb">
                            <img :src="require('@/assets/img/blog/blog3.jpg')" alt="">
                            <div class="blog-meta">
                                <div class="meta-content">
                                    <h4>31</h4>
                                    <span>Dec</span>
                                </div>
                            </div>
                        </div>
                        <div class="blog-content">
                            <div class="blog-title">
                                <a href="blog-details.html"><h2>Pros &amp; Cons of Premined Cryptocurrencies</h2></a>
                            </div>
                            <div class="blog-content-text">
                                <p>Monotonectally fabricate extensible in the
                                    after fully tested functionalities industries
                                    business experiences</p>
                            </div>
                            <div class="blog-btn">
                                <a href="#">Read More <i class="fas fa-plus"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-box">
                        <div class="blog-thumb">
                            <img :src="require('@/assets/img/blog/blog1.jpg')" alt="">
                            <div class="blog-meta">
                                <div class="meta-content">
                                    <h4>31</h4>
                                    <span>Dec</span>
                                </div>
                            </div>
                        </div>
                        <div class="blog-content">
                            <div class="blog-title">
                                <a href="blog-details.html"><h2>The Intersection Where Blockchain Meets Energy</h2></a>
                            </div>
                            <div class="blog-content-text">
                                <p>Monotonectally fabricate extensible in the
                                    after fully tested functionalities industries
                                    business experiences</p>
                            </div>
                            <div class="blog-btn">
                                <a href="#">Read More <i class="fas fa-plus"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-section pt-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="footer-location-box">
                        <div class="footer-logo">
                            <img :src="require('@/assets/img/assets/one.png')" alt="Footer-logo">
                        </div>
                        <div class="footer-content">
                            <p>There are many variations of pass of Lorem Ipsum available, but the majority have suffered</p>
                        </div>
                    </div>
                    <div class="footer-social-box">
                        <div class="social-content">
                            <h3>Follow Us</h3>
                        </div>
                        <div class="footer-about-social-icon pt-20">
                            <ul>
                                <li>
                                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="fab fa-pinterest"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="fab fa-linkedin-in"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="widget">
                        <div class="footer-quick-link">
                            <div class="footer-widget-title">
                                <h3>Help Links</h3>
                            </div>
                            <div class="footer-quick-link-list">
                                <ul>
                                    <li><a href="#">What Is ICO</a></li>
                                    <li><a href="#">Token</a></li>
                                    <li><a href="#">Road Map</a></li>
                                    <li><a href="#">Advisor</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="widget">
                        <div class="footer-quick-link-list">
                            <div class="footer-widget-title">
                                <h3>Quick Links</h3>
                            </div>
                            <div class="footer-quick-link-list">
                                <ul>
                                    <li><a href="#">ICO Apps</a></li>
                                    <li><a href="#">White Papers</a></li>
                                    <li><a href="#">Teams</a></li>
                                    <li><a href="#">Join Us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 p-0">
                    <div class="widget">
                        <div class="footer-popular-post ">
                            <div class="footer-widget-title two">
                                <h3>Newsletter</h3>
                            </div>
                            <div class="footer-content-text">
                                <p>Sent Us a Newsletter And Get Update</p>
                            </div>
                            <form>
                                <div class="subscribe-area">
                                    <input class="subscribe-mail-box" type="email" placeholder="Enter Your Email...." required="">
                                    <button class="subscribe-button" type="submit">Subscribe</button>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
            </div>
            <div class="row upper11 mt-50 align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="footer-copyright-text">
                        <p>Copyright © cryptozen all rights reserved. </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="footer-copyright-content">
                        <div class="footer-sicial-address-link">
                            <ul>
                                <li><a href="#">Terms Condition</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">FAQ</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import axios from "axios";
import Carousel from "./components/Carousel.vue";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import Marque from "@/views/components/Marque.vue";
import VueApexCharts from "vue3-apexcharts";

export default {
    name: "dashboard-default",
    components: {
        Marque,
        Navbar,
        // Card,
        // GradientLineChart,
        Carousel,
        // CategoriesCard,
        ApexChart: VueApexCharts,
    },
    data() {
        return {
            list_crypto: [],
            list_summary_data: [],
            page: 1,
            limit: 20,

            original_summary: null,
            summary_index: -1,

            hi_price: 0,
            low_price: 0,
            average_price: 0,
            change_price: 0,

            load_more_show: false,
            set_interval: null,

            series: [{
                data: []
            }],
            chartOptions: {
                chart: {
                    id: 'area-datetime',
                    type: 'area',
                    height: 450,
                    zoom: {
                        autoScaleYaxis: true
                    },
                    toolbar: {
                        show: false
                    }
                },
                annotations: {
                    yaxis: [{
                        y: 30,
                        borderColor: '#999',
                        // label: {
                        //     show: true,
                        //     text: 'Support',
                        //     style: {
                        //         color: "#fff",
                        //         background: '#00E396'
                        //     }
                        // }
                    }],
                    xaxis: [{
                        // x: new Date('14 Nov 2012').getTime(),
                        borderColor: '#999',
                        yAxisIndex: 0,
                        // label: {
                        //     show: true,
                        //     text: 'Rally',
                        //     style: {
                        //         color: "#fff",
                        //         background: '#775DD0'
                        //     }
                        // }
                    }]
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0,
                    style: 'hollow',
                },
                xaxis: {
                    type: 'datetime',
                    tickAmount: 6,
                },
                tooltip: {
                    x: {
                        format: 'dd MMM yyyy'
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 100]
                    }
                },
            },
        };
    },
    methods: {
        async initial() {
            await this.initial_crypto_list(true);
            this.set_interval = setInterval(this.initial_crypto_list, 3000);
        },
        async initial_crypto_list(concat = false) {
            let offset = concat ? (this.page - 1) * this.limit : 0;
            let limit = concat ? this.limit : this.page * this.limit;

            if (offset + limit > 2000) {
                if (concat)
                    this.page--;

                await this.initial_crypto_list();
                return;
            }

            console.log(
                concat ?
                    'Add .::. ' + offset + ' - ' + limit :
                    'Refresh .::. ' + offset + ' - ' + limit
            );

            let url = "https://api.coincap.io/v2/assets?offset=" + offset + "&limit=" + limit;
            let list = await axios.get(url);
            if (list && list.status !== 200)
                return;

            this.list_crypto = concat ? this.list_crypto.concat(list.data.data) : list.data.data;
            if (concat) {
                this.load_more_show = list.data.data.length >= limit;
            }
        },

        async signal_show_summary(index, crypto_id) {
            if (this.summary_index === index) {
                this.summary_index = -1;
                this.series[0].data = [];
                this.original_summary = null;
                return;
            }
            if (this.summary_index !== -1) {
                this.series[0].data = [];
                this.original_summary = null;
            }

            this.summary_index = index;

            let date_now = new Date();
            let date_yesterday = new Date;
            date_yesterday.setDate(date_yesterday.getDate() - 1);

            let start = date_yesterday.getTime();
            let end = date_now.getTime();

            let url = `https://api.coincap.io/v2/assets/${crypto_id.toLowerCase()}/history?interval=m1&start=${start}&end=${end}`;
            let list = await axios.get(url);
            if (list && list.status !== 200)
                return;

            let summary_list = list.data.data;

            let list_chart = [];
            let min_time = null;
            let max_time = null;

            let min_price = null;
            let max_price = null;
            let total_price = 0;

            summary_list = summary_list.map((item) => {
                let priceUsd = Number(item.priceUsd);
                if (priceUsd > 1) {
                    priceUsd = priceUsd.toFixed(2);
                    item.priceShow = this.currency_summary(priceUsd);
                }
                else {
                    priceUsd = priceUsd.toFixed(10);
                    item.priceShow = priceUsd.toString();
                }

                min_time = Math.min(min_time || item.time, item.time);
                max_time = Math.max(max_time || item.time, item.time);

                min_price = Math.min(min_price || priceUsd, priceUsd);
                max_price = Math.max(max_price || priceUsd, priceUsd);

                total_price += Number(priceUsd);

                list_chart.push([
                    item.time, item.priceUsd
                ]);
                return item;
            })

            // this.change_price = Math.abs(max_price - min_price) / Math.floor((max_price + min_price) / 2) * 100;
            this.change_price = Math.abs(max_price - min_price) / (max_price + min_price) / 2 * 100;
            this.change_price = this.currency_summary(this.change_price);

            if (max_price - min_price > 1) {
                max_price = this.currency_summary(max_price);
                min_price = this.currency_summary(min_price);

                for (const chart_index in list_chart) {
                    list_chart[chart_index][1] = this.currency_summary(list_chart[chart_index][1], false);
                }
            }

            this.chartOptions.xaxis.min = min_time;
            this.chartOptions.xaxis.max = max_time;

            this.hi_price = max_price;
            this.low_price = min_price;

            let average = total_price / summary_list.length;
            console.log(total_price, summary_list.length, average);
            this.average_price = average > 1 ? this.currency_summary(average) : average.toFixed(10);

            this.series[0].data = list_chart;
            this.original_summary = list.data.data;
        },
        async signal_load_more() {
            this.load_more_show = false;
            this.page++;

            await this.initial_crypto_list(true);
            await clearInterval(this.set_interval);
            this.set_interval = setInterval(this.initial_crypto_list, 3000);
        },

        currency_summary(amount, doted = true) {
            let number = Number(amount) >= 1.0e+12 ?
                (Number(amount) / 1.0e+12).toFixed(2) + " t"
                : Number(amount) >= 1.0e+9 ?
                    (Number(amount) / 1.0e+9).toFixed(2) + " b"
                    : Number(amount) >= 1.0e+6 ?
                        (Number(amount) / 1.0e+6).toFixed(2) + " m"
                        : Number(amount).toFixed(2);
            // : Number(amount) >= 1.0e+3 ?
            // 	(Number(amount) / 1.0e+3).toFixed(2) + "k"
            // 	: Number(amount);

            if (doted)
                return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            return number;
        }
    },
    mounted() {
        this.initial();
    },
    beforeUnmount() {
        clearInterval(this.set_interval);
    },
    unmounted() {
    }
};
</script>

<style>
.container-fluid {
    padding-top: 1rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.single-feature-box {
    /*background: #09185E;*/
    position: relative;
    z-index: 1;
    padding: 29px 29px 29px;
    border-radius: 5px;
    margin-top: 3rem;
}

.single-feature-box:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    transition: .5s;
    border-radius: 4px;
    z-index: -1;
    background: -webkit-linear-gradient(-9deg, #0A2C56 0%, #441459 97%);
}

.feature-thumb {
    margin-bottom: 20px;
}

img {
    vertical-align: middle;
    border-style: none;
}

.feature-content span {
    font-size: 18px;
    color: #486178;
    margin: 0;
    font-weight: 600;
}

.feature-content h3 {
    font-size: 22px;
    font-weight: 500;
    color: #fff !important;
}

.feature-content p {
    color: #375079;
    font-weight: 600;
}

.feature-section {
    margin-top: 3rem;
}

.feature-section .shape {
    top: 29px;
    right: 28px;
    position: absolute;
    left: inherit;
}

h1, h2, h3, h4, h5, h6 {
    /*font-family: 'Inter', sans-serif;*/
    font-style: normal;
    margin: 0 0 10px;
    line-height: 1.2;
    font-weight: 700;
    transition: .5s;
}

.feature-detail {
    display: grid;
    grid-template-columns: 1fr auto;
    align-content: center;
    align-items: center;
    margin: 13px 0 0;
}


.single-feature-box-bitcoin {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, rgba(0, 147, 251, 1) 100%);
}

.single-feature-box-ethereum {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, rgba(223, 49, 201, 1) 100%);
}

.single-feature-box-tether {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, rgba(71, 220, 167, 1) 100%);
}
</style>
<style>
.choose-section {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 3rem;
}

.pb-20 {
    padding-bottom: 20px;
}

.dreamit-section-sub-title h5 {
    font-size: 18px;
    margin: 0 0 12px;
    font-weight: 300;
    position: relative;
    left: 72px;
    z-index: 1;
    display: inline-block;
}
html[dir='rtl'] .dreamit-section-sub-title h5 {
    left: auto !important;
    right: 72px;
}

.dreamit-section-sub-title h5:before {
    position: absolute;
    content: "";
    top: 17px;
    left: -73px;
    height: 2px;
    width: 59px;
    background: #0093FA;
}
html[dir='rtl'] .dreamit-section-sub-title h5:before {
    display: none !important;
}
html[dir='rtl'] .dreamit-section-sub-title h5:after {
    position: absolute;
    content: "";
    top: 17px;
    right: -73px;
    height: 2px;
    width: 59px;
    background: #0093FA;
}

.dreamit-section-main-title h1 {
    font-size: 42px;
    margin: 0 0 22px;
}

.pt-25 {
    padding-top: 25px;
}

.choose-single-box {
    background-color: #fff;
    padding: 43px 26px 16px;
    transition: .5s;
    border-radius: 4px;
    margin-bottom: 30px;
}

.choose-content h2 {
    font-size: 1.7rem;
    margin: 36px 0 15px;
    font-weight: bold;
    line-height: 1.4;
}
</style>
<style>
.blog-section {
    margin-top: 3rem;
}

.single-blog-box {
    margin-bottom: 30px;
    transition: .5s;
    box-shadow: 0 0 24px rgb(255 255 255 / 9%);
    position: relative;
    z-index: 1;
}

.single-blog-box:hover .blog-thumb:before {
    left: 0;
    background: linear-gradient(to top, #37A3FE 0%, rgba(0, 0, 0, .1) 73%);
}

.blog-thumb {
    position: relative;
    z-index: 1;
    transition: .5s;
}

.blog-thumb:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgba(164, 85, 84, .2) 69%, #46DDA8 100%);
    right: 0;
    top: 0;
    transition: all 5s ease-in-out;
}

.blog-thumb img {
    width: 100%;
}

.blog-meta {
    height: 91px;
    width: 88px;
    background: #4AF0C4;
    text-align: center;
    padding: 18px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-left-radius: 30px;
}

.meta-content h4 {
    font-size: 28px;
    color: #fff;
    font-weight: 600;
    margin: 0 0 0;
}

.meta-content span {
    color: #fff;
    font-size: 16px;
}

.blog-content {
    left: 35px;
    bottom: 0;
    padding: 24px 0 22px 23px;
    background: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.blog-title h2 {
    font-size: 24px;
    margin: 0 0 12px;
    position: relative;
    transition: .5s;
    color: #08195E;
    font-weight: 600;
}

.blog-content-text p {
    transition: .5s;
    color: #616161;
    margin: 0 0 11px;
}

.blog-btn a {
    display: inline-block;
    transition: .5s;
    color: #06305f;
    font-size: 16px;
    font-weight: 600;
}

.blog-btn a i {
    font-size: 13px;
    margin-left: 5px;
    display: inline-block;
}

</style>

<style>
.footer-section {
    margin-top: 3rem;
}

.footer-content p {
    margin: 21px 0 27px;
}

.social-content h3 {
    font-size: 20px;
    margin: 0 0 0;
}

.pt-20 {
    padding-top: 20px;
}

.footer-about-social-icon ul li {
    display: inline-block;
    margin-right: 7px;
}

.footer-about-social-icon ul li a {
    display: inline-block;
    height: 42px;
    width: 42px;
    line-height: 42px;
    text-align: center;
    transition: .5s;
    position: relative;
    overflow: hidden;
    /*background: #333067;*/
    border-radius: 100%;
    z-index: 1;
    border: 1px solid #eee;
}

.footer-about-social-icon ul li a:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    transition: .5s;
    z-index: -1;
    height: 100%;
    width: 0;
    border-radius: 100%;
    background: #0194FC;
}

.footer-about-social-icon ul li a:hover:after {
    left: 0;
    width: 100%;
}

.footer-about-social-icon ul li a:hover {
    color: #fff;
}

.widget {
    position: relative;
    top: 21px;
}

.footer-widget-title h3 {
    font-size: 20px;
    margin: 0 0 39px;
}

.footer-quick-link-list ul li {
    list-style: none;
    margin-bottom: 9px;
}

.footer-quick-link-list ul li a {
    display: inline-block;
    transition: .5s;
    position: relative;
    left: 24px;
    transition: .5s;
    /*background-image: linear-gradient( to right, #37A3FE, #37A3FE, 50%, #a2bbd2 50%);*/
    /*background-size: 200% 100%;*/
    /*background-position: 100%;*/
    font-weight: 600;
}
html[dir='rtl'] .footer-quick-link-list ul li a {
    left: auto !important;
    right: 24px;
}

.footer-quick-link-list ul li a:before {
    position: absolute;
    content: "";
    top: 11px;
    height: 1px;
    width: 16px;
    left: -24px;
    background: #a2bbd2;
}
html[dir='rtl'] .footer-quick-link-list ul li a:before {
    display: none !important;
}
html[dir='rtl'] .footer-quick-link-list ul li a:after {
    position: absolute;
    content: "";
    top: 11px;
    height: 1px;
    width: 16px;
    right: -24px;
    background: #a2bbd2;
}





.subscribe-area {
    position: relative;
}

input.subscribe-mail-box {
    border: 1px solid #eee;
    padding: 14px 21px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    outline: 0;
    width: 90%;
}
html[dir='rtl'] input.subscribe-mail-box {
    border-radius: 0 30px 30px 0;
}

button.subscribe-button {
    position: absolute;
    right: 0;
    background: #0194FC;
    border: none;
    height: 54px;
    width: 113px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
    outline: 0;
}
html[dir='rtl'] button.subscribe-button {
    left: 0;
    right: auto !important;

    border-radius: 30px 0 0 30px;
}

.footer-content-text {
    margin-bottom: 0.4rem;
}

.mt-50 {
    margin-top: 50px !important;
}

.row.upper11 {
    border-top: 1px solid #3E407B;
    padding: 33px 0 31px;
}

.footer-copyright-text p {
    margin: 0;
}

.footer-copyright-content {
    float: right;
}
html[dir='rtl'] .footer-copyright-content {
    float: left;
}

.footer-sicial-address-link ul li {
    display: inline-block;
    margin-left: 24px;
}
</style>

<style>
@media screen and (max-width: 992px) {
    .crypto-header {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr !important;
        grid-row-gap: 0.5rem !important;
    }
    .table-crypto thead tr th:nth-child(6), .table-crypto thead tr th:last-child,
    .table-crypto tbody tr.cursor-pointer td:nth-child(6), .table-crypto tbody tr.cursor-pointer td:last-child{
        display: none;
    }
}
@media screen and (max-width: 768px) {
    .crypto-stats {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr !important;
        grid-row-gap: 0.5rem !important;
    }
    .crypto-stats > div {
        grid-row-gap: 0.5rem !important;
    }
    .crypto-chart {
        margin-top: 1rem !important;
    }
    .table-crypto thead tr th:nth-child(4), .table-crypto thead tr th:nth-child(5),
    .table-crypto tbody tr.cursor-pointer td:nth-child(4), .table-crypto tbody tr.cursor-pointer td:nth-child(5){
        display: none;
    }
}
</style>
